*
    box-sizing: border-box
    border: none
    margin: 0
    padding: 0


html
    color: #333
    line-height: 1.3
    height: 100%

body
    position: relative
    height: 100%
    background: #fafafa
    -webkit-font-smoothing: antialiased
    -moz-font-smoothing: antialiased
    -o-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale


html *
    font-family: 'Open Sans', sans-serif
    box-sizing: border-box
    color-profile: sRGB
    rendering-intent: auto

a
    text-decoration: none

    &,
    &:visited
        color: inherit

    &:hover,
    &:active
         outline: 0

img
    vertical-align: top

ul
    padding: 0
    margin: 0

p
    font-size: 1.063rem
    margin: 0 0 .9em
    line-height: 1.54em
    display: inherit
    text-rendering: optimizelegibility


.clearfix:before,
.clearfix:after
    content: ''
    display: table

.clearfix:after
    clear: both


.placeholder
    min-height: 800px

.section
    padding: 50px 30px
    max-width: 100%

    @media only screen and (min-width: $breakpoint-tablet)
        padding: 80px 50px

    @media only screen and (min-width: $breakpoint-desktop-small)
        padding: 100px


.thanks--content

    display: flex
    -webkit-box-pack: center
    -webkit-justify-content: center
            -ms-flex-pack: center
                    justify-content: center
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    flex-direction: column
    box-shadow: 0px 2px 2px 0px rgba(209,209,209,0.50)


.thanks--content
    background-color: #ffffff
    padding: 2em 1.75em 3em
    max-width: 1000px
    margin: 2em 0
    text-align: center
    @media only screen and (min-width: 700px)
        border-radius: 4px
        padding: 2em 2em 3em
        margin: 2em 1em


    @media only screen and (min-width: 1000px)
        margin: 2em auto



.thanks--header-title
    color: #FF9E00


.success .thanks--header-title
    color: #82D867


.thanks--header p
    font-size: 1.063rem
    @media only screen and (min-width: 700px)
        font-size: 1.125rem


    @media only screen and (min-width: 1200px)
        font-size: 1.378rem



.thanks--facebook,
.thanks--cancel
    display: flex


    a
        padding: .55em 2em
        margin: .5em auto
        display: block
        color: #ffffff
        border-radius: 2px
        text-transform: uppercase
        background-color: #4267b2

        @media only screen and (min-width: 700px)
            padding: .75em 4em


        @media only screen and (min-width: 1200px)
            padding: .8em 7em



.success .thanks--facebook a
    background-color: #82D867


.success .thanks--cancel a
        background-color: #D88267



a:hover.thanks--facebook,
a:hover.thanks--cancel
    background-color: #365899


.thanks--social
    display: flex
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -webkit-flex-direction: column
            -ms-flex-direction: column
                    flex-direction: column
    -webkit-box-pack: center
    -webkit-justify-content: center
            -ms-flex-pack: center
                    justify-content: center


.thanks--social-label
    color: #888888
    font-size: .853rem
    text-transform: uppercase
    padding: 1em 0


.thanks--social-icons
    display: flex
    -webkit-box-pack: center
    -webkit-justify-content: center
        -ms-flex-pack: center
            justify-content: center

    a
        display: flex
        content: ''
        width: auto
        padding: .5em 1em
        border-radius: 4px
        -webkit-box-pack: center
        -webkit-justify-content: center
                -ms-flex-pack: center
                        justify-content: center

        align-items: center
        margin: 0 .5em



        color: #ffffff
        background-color: #353535

        &:hover.facebook
            background-color: #365899

        &:hover.twitter
            background-color: #1da1f2


    p
        margin: 0 .5em

/*
 *
 * Pricing
 *
 */

.thanks
    background-color: #fafafa
    padding: 6em 0 3em
    @media only screen and (min-width: 1200px)
        padding: 8em 0 4em

    @media only screen and (min-width: 1800px)
        padding: 10em 0 8em



.thanks--hero
    margin: 1em 0
    text-align: center
    display: flex
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -webkit-flex-direction: column
      -ms-flex-direction: column
          flex-direction: column
    -webkit-box-align: center
    -webkit-align-items: center
      -ms-flex-align: center
          align-items: center




.social
    box-sizing: border-box
    outline: none
    border: none
    box-shadow: none !important
    text-shadow: none
    height: 36px
    line-height: 36px
    transition: all .15s ease
    padding: 0 10px
    margin: 0
    display: inline-block
    border-radius: 4px
    font-size: 15px

    &:focus
        outline: none
        text-decoration: none

    &:hover
        text-decoration: none

    &:active
        transform: scale(0.98)

.social-xl
    padding: 0 10px
    height: 44px
    line-height: 44px
    font-size: 18px

.social-fb
    background: #39579a
    color: #fff
    &:hover,
    &:focus,
    &:active
        background: #324c87
        color: #fff
    .svg-wrapper svg
        top: 3px

.social-fb[disabled],
.social-fb.disabled
    border: none

.social-fb-inverse
    background: #fff
    color: #39579a
    border: 1px solid #39579a

    &:hover,
    &:focus,
    &:active
        background: #324c87
        color: #fff

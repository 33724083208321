
form
    width: 100%
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-align: center
    -webkit-align-items: center
            -ms-flex-align: center
                    align-items: center
    -webkit-flex-wrap: wrap
            -ms-flex-wrap: wrap
                    flex-wrap: wrap


.select
    margin: .5em 0
    width: 100%
    position: relative


    &:before
        content: '\25bc'
        color: #808080
        position: absolute
        pointer-events: none
        top: 0
        right: 0
        line-height: 1
        width: 4em
        bottom: 1px
        text-align: center
        border-radius: 1px
        padding-top: 1.1em


select, textarea
    min-width: 280px
    width: 100%
    margin: 1em 0
    padding: 1em
    border-radius: 4px
    -webkit-box-flex: 3
    -webkit-flex-grow: 3
            -ms-flex-positive: 3
                    flex-grow: 3
    -webkit-flex-basis: 350px
            -ms-flex-preferred-size: 350px
                    flex-basis: 350px
    resize: none
    font-size: 1.063rem

.input:focus
    outline: 0

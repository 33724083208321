.ml-5
    margin-left: 5px

.ml-10
    margin-left: 10px !important

.ml-15
    margin-left: 15px !important

.ml-20
    margin-left: 20px

.ml-25
    margin-left: 25px

.ml-30
    margin-left: 30px !important

.ml-35
    margin-left: 35px

.mb-0
    margin-bottom: 0 !important

.mb-5
    margin-bottom: 5px !important

.mb-8
    margin-bottom: 8px !important

.mb-10
    margin-bottom: 10px !important

.mb-15
    margin-bottom: 15px !important

.mb-16
    margin-bottom: 16px !important

.mb-20
    margin-bottom: 20px !important

.mb-30
    margin-bottom: 30px !important

.mb-40
    margin-bottom: 40px !important

.mb-50
    margin-bottom: 50px !important

.mr-5
    margin-right: 5px !important

.mr-10
    margin-right: 10px !important

.mr-15
    margin-right: 15px !important

.mr-20
    margin-right: 15px !important

.mt-5
    margin-top: 5px !important

.mt-10
    margin-top: 10px !important

.mt-12
    margin-top: 12px !important

.mt-16
    margin-top: 16px !important

.mt-20
    margin-top: 20px !important

.mt-24
    margin-top: 24px !important

.mt-40
    margin-top: 40px !important

$btn-large-height:                      40px
$btn-default-height:                    36px
$btn-medium-height:                     30px
$btn-small-height:                      26px

$disabled-button-color:                 #e7e7e7


/**
 * Styles for primary button
 */


.btn,
%btn
    box-sizing: border-box
    outline: none
    border: none
    box-shadow: none !important
    text-shadow: none
    height: $btn-default-height
    transition: all .15s ease
    padding: 0 10px
    margin: 0
    display: inline-flex
    justify-content: center
    align-items: center
    border-radius: $base-border-radius
    font-size: 15px

    &:focus
        outline: none
        text-decoration: none

    &:hover
        text-decoration: none

    &:active
        transform: scale(.98)

    &[disabled],
    &.disabled
        color: #fff !important
        background: $disabled-button-color !important
        border: 1px solid $disabled-button-color
        font-weight: 600
        cursor: not-allowed

    .svg-status,
    .svg-wrapper
        margin-right: 5px
        display: inline-block

        svg
            position: relative
            top: 2px

.btn-primary
    border-radius: 25px
    font-weight: 600
    background: $brand
    color: $midnight

    &:active,
    &:focus
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0)

    &:focus:not([disabled], .disabled),
    &:hover:not([disabled], .disabled)
        color: $midnight
        background: $brand-hover

    &:visited
        color: $midnight

.btn-m
    padding: 0 10px
    height: $btn-medium-height
    line-height: $btn-medium-height
    font-size: 14px

.btn-lg
    padding: 0 10px
    height: $btn-large-height
    font-size: 18px


.btn-block
    width: 100%
    text-align: center

.btn-max240
    max-width: 240px



.btn-default
    color: #fff
    background: #bababa

h1,h2,h3,
.h2, .h3, .h4, .h5
    display: block
    letter-spacing: normal
    margin: 0 0 10px
    line-height: 1.27em
    text-rendering: optimizelegibility
    font-family: 'Frank Ruhl Libre', sans-serif


h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small
    font-family: inherit
    font-weight: normal
    color: #7b7b7b


h1, .h1
    font-size: 24px
    font-weight: 700


    @media only screen and (min-width: 768px)
        h1, .h1
            font-size: 30px



@media only screen and (min-width: 1024px)
        h1, .h1
            font-size: 36px


h2, .h2
    font-size: 1.063rem
    @media only screen and (min-width: 1000px)
        h2, .h2
            font-size: 1.375rem


    @media only screen and (min-width: 1200px)
        h2, .h2
            font-size: 1.75rem


h3
    font-size: 24px
    font-weight: 700

    @media only screen and (min-width: $breakpoint-tablet)
        font-size: 32px


h5
    font-size: 16px
    font-weight: 400

    @media only screen and (min-width: $breakpoint-tablet)
        font-size: 22px

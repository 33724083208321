.menu
    opacity: 0
    visibility: hidden !important
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: center
    -webkit-transition: 0.2s ease-out
    transition: 0.2s ease-out
    z-index: 1000


.menu.active
    visibility: visible !important
    overflow: hidden


.menu--background
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: center
    z-index: 1500


.menu, .menu--background
    position: fixed
    top: 0
    right: 0
    bottom: 0
    z-index: 10
    height: 100%
    overflow: hidden


.menu.active
    opacity: 1


.menu
    background-color: #fff


.menu .header--content
    background-color: #fff
    position: absolute
    top: 0
    left: 0
    right: 0


    @media only screen and (min-width: 700px)
        padding-right: .75em


    @media only screen and (min-width: 1000px)
        padding: .75em 1em .75em 0



.menu--content
    height: 100%
    width: 260px

.menu--content
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    flex-direction: column
    max-width: 700px





.menu--navigation
    margin: 4.6em 0 1em 1.5em
    overflow: auto
    letter-spacing: 0.02em


.menu--navigation ul
    display: flex
    flex-grow: 1
    flex-basis: 100%
    flex-wrap: wrap


.menu--navigation ul li
    font-size: 14px
    font-weight: bold
    color: $midnight


.menu--navigation ul li a
    -webkit-transition: 0.2s ease-out
    transition: 0.2s ease-out

.menu--navigation ul li
    list-style: none


.menu--navigation ul li a
    display: block
    width: 100%
    margin-bottom: 16px



.menu--navigation ul li
    flex-grow: 1
    flex-shrink: 1
    flex-basis: 350px


.menu--navigation ul li
    position: relative


.menu--navigation ul li a:hover
    color: #FF9E00


.menu--navigation ul li a:hover::after
    left: 20%
    width: 200px

.four-o-four
    padding-top: 160px

    @media only screen and (min-width: 1024px)
        padding-top: 110px

    .menu--header-items .main
        transition: .4s ease-out

    .header
        transition: background-color .2s ease

        @media only screen and (max-width: 1023px)
            background-color: #106BE0

        &:hover
            background-color: #106BE0

        &:not(:hover)
            .menu--header-items .main,
            .menu--header-items .facebook-signup
                text-shadow: 0px 3px 10px #000

.four-o-four__text-wrapper
    margin-bottom: 50px
    position: relative
    z-index: 1

.four-o-four__text

    @media only screen and (min-width: $breakpoint-tablet)
        text-shadow: 0px 3px 10px #000
        color: #fff

.four-o-four__video-wrapper
    @media only screen and (max-width: $breakpoint-tablet - 1)
        position: relative
        width: 100%
        padding-top: 56%

.four-o-four__video
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%

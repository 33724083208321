.text-center
    text-align: center

.text-left
    text-align: left

.text-right
    text-align: right

.text-uppercase
    text-transform: uppercase

.text-lowercase
    text-transform: lowercase

.text-regular
    font-weight: 400

.text-semibold
    font-weight: 600

.text-bold
    font-weight: 700

.text-underline
    text-decoration: underline

.pointer
    cursor: pointer

.break-word
    word-break: break-word

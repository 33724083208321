// Modals
$overlay-solid:                         #FAFAFA

// Navbar
$navbar-background:                     #266093
$navbar-active:                         #fff
$navbar-link:                           #a0e8fe

// Layout
$header-height:                         56px
$aside-width:                           240px

// Text colors
$small-text-color:                      #7c7c7c
$default-text-color:                    #333
$new-text-color:                        #212b36 // for ui components

// Borders
$base-border-color:                     #DFE3E8
$base-border-radius:                    3px

// Colors
$oct-btn-blue-color:                    #266093
$octie-blue-color:                      #007ACE
$octie-orange-color:                    #FA9F1E
$octie-svg-color:                       #919eab
$brand: #f5c073
$brand-hover:                           #FFBD5F
$oxygen:                                #DFE7E9
$sand:                                  #F8EEE3
$midnight: #363959
$midnight-hover:                        rgba(38, 41, 65, .6)

// Spacing
$base-spacing:                          5px
$default-section-padding:               32px

// Sizes
$max-page-size:                         1000px
$submenu-height:                        56px
$submenu-border-width:                  2px
$new-max-page-size:                     934px
$max-list-page-size:                    2000px


.nav-logo
    height: 40px
    width: auto
    margin: 0 1em


.type-logo
    font-weight: bold
    font-size: .94rem
    letter-spacing: .159rem
    text-transform: uppercase
    z-index: 50
    -webkit-transition: .3s ease-out
    transition: .3s ease-out

.type-logo,
.navigation--open,
.navigation--start
    padding: 0 20px

.svg-logo
    width: 124px
    height: 34px


.svg-logo--white
    color: #262941

.svg-logo--silver
    color: #5A5A60

.svg-flame-logo
    display: none

.navigation--open,
.navigation--start
    font-size: 0.9375rem
    letter-spacing: 2.09px


.light-white.header,
.light-white.headroom--top
    background-color: #FFFFFF

.light-white.header
    box-shadow: 0px 2px 2px 0px rgba(209,209,209,0.50)

.header--content
    display: flex
    flex: 1
    -webkit-box-pack: justify
    -webkit-justify-content: space-between
    -ms-flex-pack: justify
    justify-content: space-between
    padding: 2px 0
    max-width: 1460px
    padding: 0 10px 0 30px
    margin: 0 auto
    padding: 0 30px

.flex-h-center
    display: flex
    align-items: center

.navigation--large
    flex-grow: 1
    justify-content: flex-end

.navigation, .type-logo
    display: flex

.navigation a:hover.navigation--start::after
    width: 100%

.logo .type-logo,
.navigation .navigation--open,
.navigation .navigation--start
    color: $midnight

.logo .type-logo
    position: relative
    margin: 18px 0 15px
    padding-left: 0

.light-white.logo .type-logo,
.light-white .navigation .navigation--open
    color: #353535

.logo a:hover span, a:hover.navigation--open span
    color: #353535
    z-index: 1

.logo a:hover .nav-logo
    opacity: .85

a:hover.navigation--start span
    color: #0A52AE
    z-index: 1


.menu--header
    display:  block
    width: 100%

.menu--header-items
    display: flex
    justify-content: center
    align-items: center
    position: relative
    line-height: 70px
    justify-content: space-between

.menu-items-wrap > a,
.menu-items-wrap > .main
    font-size: 1rem
    margin: 0 .8em
    position: relative
    color: #262941

    &:hover
        cursor: pointer

        .sub-menu
            display: block

    &.try-now-wrap
        margin: 0 0 0 .8em

.menu-items-wrap > span.main
    padding-right: 15px
    display: inline-block
    height: 70px

    &:after
        content: ''
        width: 0
        height: 0
        border-left: 5px solid transparent
        border-right: 5px solid transparent
        border-top: 5px solid $midnight
        position: absolute
        right: 0
        transform: translate(0, -50%)
        top: 50%
        transition: transform 0.2s ease-out

    &:hover
        &:after
            transform: translate(0, -50%) rotate(180deg)

.menu--header-items .main
    color: $midnight
    font-weight: 600

.light-white .menu--header-items .main
    color: #353535

.menu--header-items a:hover
    color: $midnight-hover

.light-white .menu--header-items a:hover
    color: $midnight-hover

.facebook-signup
    border: 1px solid #fff
    color: $midnight
    background-color: #fff
    padding: 16px 24px
    border-radius: 25px
    position: relative
    overflow: hidden
    -webkit-transition: 0.4s ease-out
    transition: 0.4s ease-out
    font-weight: 600
    font-size: 18px
    z-index: 0
    height: 56px
    line-height: 56px
    display: flex
    justify-content: center
    align-items: center
    line-height: initial
    border: 2px solid $brand

a.facebook-signup::after
    position: absolute
    content: ''
    height: 100%
    width: 0
    left: 0
    top: 0
    -webkit-transition: 0.2s ease-out
    transition: 0.2s ease-out
    background-color: #ffffff

a:hover.facebook-signup span
    position: inherit
    z-index: 2

.light-white .menu--header-items .facebook-signup
    border: 1px solid #106BE0
    color: #106BE0
    padding: .7em 1.25em
    border-radius: 4px
    position: relative
    overflow: hidden
    -webkit-transition: 0.4s ease-out
    transition: 0.4s ease-out

.light-white .menu--header-items a.facebook-signup::after
    position: absolute
    content: ''
    height: 100%
    width: 0
    left: 0
    top: 0
    -webkit-transition: 0.2s ease-out
    transition: 0.2s ease-out
    background-color: #106BE0

.light-white .menu--header-items a:hover.facebook-signup span
    position: inherit
    z-index: 2

.light-white .menu--header-items a:hover.facebook-signup::after
    width: 100%

.menu__sign-up
    margin-left: 8px


.book-demo
    height: 40px
    width: 186px
    border-radius: 25px
    background-color: $brand
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 16px
    transition: background-color 175ms ease

    &:hover
        background-color: $brand-hover

.cta-buttons
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

@media only screen and (min-width: 700px)
    .cta-buttons
      flex-direction: row

@media only screen and (min-width: 700px)
    .book-demo
      margin-right: 20px
      margin-bottom: 0
      height: 50px
      width: 230px

.secondary-button
    height: 40px
    width: 186px
    border-radius: 25px
    background-color: #FFF
    border: 2px solid $brand
    font-weight: bold
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: center

.homepage-button
    width: 140px

    @media only screen and (min-width: 700px)
        height: 50px
        width: auto
        padding: 0 28px

.case-studies
    margin: 0 auto
    width: auto
    width: 240px

.secondary-button.small
    width: 115px

@media only screen and (min-width: 700px)
    .secondary-button.hero-button
      height: 50px
      width: 230px

.try-now-wrap
    height: 40px
    border-radius: 20px
    border: 2px solid $brand
    display: inline-flex
    justify-content: center
    align-items: center
    width: 122px

    &.header-try-now.scrolled
        background: $brand

.try-now
    font-size: 16px
    font-weight: bold
    color: #262941

.sub-menu
    position: absolute
    left: 50%
    transform: translate(-50%, 0)
    background: #fff
    border-radius: 4px
    padding: 12px 15px
    border: 1px solid #e7e7e7
    display: none
    min-width: 240px
    box-shadow: 0 5px 10px rgba(0,0,0,.2)

    &:before,
    &:after
        content: ''
        width: 0
        height: 0
        position: absolute
        left: 50%
        transform: translate(-50%, -100%)
        top: 0

    &:before
        border-left: 10px solid transparent
        border-right: 10px solid transparent
        border-bottom: 10px solid #DFE3E8

    &:after
        border-left: 9px solid transparent
        border-right: 9px solid transparent
        border-bottom: 9px solid #fff

    a.main
        color: #333
        display: block
        line-height: 1.8
        padding: 5px 0

        &:hover
            text-decoration: underline

